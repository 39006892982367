<template>
  <fair-layout v-if="fair" :fair-id="fair.id">
    <template v-slot:banner>
      <banner
        v-if="fair"
        :title="fair.name"
        type="banner--admin"
        :options="{ padding: '30px' }"
      >
      </banner>
    </template>
    <template v-slot:content>
      <div class="px-7">
        <div class="row">
          <div class="col-12 d-flex align-center justify-space-between">
            <h2>Configuración de Sitios de Interes</h2>
            <v-btn
              color="secondary"
              class="text--primary text-none elevation-0"
              @click="openInterestPlaceModal()"
              dark
            >
              Nuevo Sitio de Interes
            </v-btn>
          </div>
        </div>
        <div class="row">
          <div class="col-12">
            <data-table
              ref="interestPlaces"
              :url="url"
              :params="params"
              :headers="headers"
              :withoutPagination="true"
              :mobile="true"
            >
              <template v-slot:item.urlWebsite="{ item }">
                <a v-if="item" :href="item">Ver sitio web</a>
              </template>
              <template v-slot:item.urlGoogleMap="{ item }">
                <a v-if="item" :href="item">Ver ubicación en Google Maps</a>
              </template>
              <template v-slot:item.urlWazeMap="{ item }">
                <a v-if="item" :href="item">Ver ubicación en Waze</a>
              </template>
              <template v-slot:item.arrows="{ item }">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon
                      v-if="!item.last"
                      large
                      color="black"
                      class="ml-5"
                      center
                      v-bind="attrs"
                      v-on="on"
                      @click="onChangeOrderInterestPlace(item, 0)"
                    >
                      fa fa-angle-down
                    </v-icon>
                    <v-icon large class="ml-10 pl-1" v-else></v-icon>
                  </template>
                  <span>Subir Posición</span>
                </v-tooltip>
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon
                      v-if="!item.first"
                      large
                      color="black"
                      class="ml-5"
                      center
                      v-bind="attrs"
                      v-on="on"
                      @click="onChangeOrderInterestPlace(item, 1)"
                    >
                      fa fa-angle-up
                    </v-icon>
                    <v-icon large class="ml-5" v-else></v-icon>
                  </template>
                  <span>Bajar Posición</span>
                </v-tooltip>
              </template>
              <template v-slot:item.isActive="{ item }">
                <v-switch
                  v-model="item.isActive"
                  dense
                  hide-details
                  class="ma-0"
                  @click="onChangeStatusInterestPlace(item)"
                ></v-switch>
              </template>
              <template v-slot:item.actions="{ item }">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      outlined
                      class="ma-2 elevation-0"
                      v-bind="attrs"
                      v-on="on"
                      @click="openInterestPlaceModal(item)"
                      small
                      color="primary"
                    >
                      <v-icon center dark small>
                        fa fa-edit
                      </v-icon>
                    </v-btn>
                  </template>
                  <span>Editar Sitio de Interes</span>
                </v-tooltip>
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      outlined
                      class="ma-2 elevation-0"
                      v-bind="attrs"
                      v-on="on"
                      @click="onDeleteInterestPlace(item)"
                      small
                      color="primary"
                    >
                      <v-icon center dark small>
                        fa fa-trash
                      </v-icon>
                    </v-btn>
                  </template>
                  <span>Eliminar Actividad</span>
                </v-tooltip>
              </template>
            </data-table>
          </div>
        </div>
        <interest-place-modal
          :fairId="fair.id"
          ref="interestPlaceModal"
          @onClose="onClose"
        />
        <confirm-dialog
          title="Confirmación"
          :description="
            `¿Deseas ${
              Boolean(selectedInterestPlace.isActive) ? 'activar' : 'desactivar'
            } el Sitio de Interes?`
          "
          @onClose="closeChangeStatusInterestPlaceModal()"
          @onConfirm="changeStatusGeneralActivity()"
          :is-active="isConfirmChangeStatusInterestPlace"
        />
        <confirm-dialog
          title="Confirmación"
          :description="
            `¿Deseas eliminar el sitio de interes ${selectedInterestPlace.name}?`
          "
          @onClose="closeDeleteInterestPlaceModal()"
          @onConfirm="deleteInterestPlace()"
          :is-active="isConfirmDeleteInterestPlace"
        />
      </div>
    </template>
  </fair-layout>
</template>

<script>
import { INTEREST_PLACES_URL } from '@/constants/ServicesMobileConstants'
import { FAIRS_URL } from '@/constants/ServicesConstants'
import FairLayout from '@/layouts/FairLayout'
import headers from '@/views/private/interest-places/data/Headers'
import DataTable from '@/components/data-table/DataTable'
import crudServiceMixin from '@/mixins/crudServiceMixin'
import crudServiceMobileMixin from '@/mixins/crudServiceMobileMixin'
import notificationMixin from '@/mixins/notificationMixin'
import ConfirmDialog from '@/components/confirm-dialog/ConfirmDialog'
import sessionMixin from '@/mixins/sessionMixin'
import InterestPlaceModal from '@/views/private/interest-places/components/InterestPlaceModal.vue'
import Banner from '@/components/banner/Banner'
import loaderMixin from '../../../mixins/loaderMixin'

export default {
  beforeRouteEnter (to, from, next) {
    const user = sessionMixin.methods.getUser()
    if (sessionMixin.methods.isSuperAdmin(user.roleId)) {
      next()
    } else {
      next({ path: '/error-403' })
    }
  },
  name: 'InterestPlaces',
  components: {
    FairLayout,
    Banner,
    DataTable,
    ConfirmDialog,
    InterestPlaceModal
  },
  data () {
    return {
      headers,
      params: {
        fairId: this.$route.params.fairId
      },
      fair: null,
      selectedInterestPlace: {},
      isConfirmDeleteInterestPlace: false,
      isConfirmChangeStatusInterestPlace: false,
      url: INTEREST_PLACES_URL
    }
  },
  methods: {
    openInterestPlaceModal (item) {
      this.$refs.interestPlaceModal.open(item)
    },
    onChangeOrderInterestPlace (item, toMove) {
      this.selectedInterestPlace = item
      this.selectedInterestPlace.toMove = toMove
      this.changeOrderInterestPlace()
    },
    onChangeStatusInterestPlace (item) {
      this.selectedInterestPlace = item
      this.isConfirmChangeStatusInterestPlace = false
      this.changeStatusInterestPlace()
    },
    onDeleteInterestPlace (item) {
      this.selectedInterestPlace = item
      this.isConfirmDeleteInterestPlace = true
    },
    onClose () {
      this.$refs.interestPlaces.getDataFromApi()
    },
    closeDeleteInterestPlaceModal () {
      this.isConfirmDeleteInterestPlace = false
    },
    closeChangeStatusInterestPlaceModal () {
      this.isConfirmChangeStatusInterestPlace = false
      this.selectedInterestPlace.isActive = !this.selectedInterestPlace
        .isActive
    },
    changeOrderInterestPlace () {
      this.getMobile(
        INTEREST_PLACES_URL +
          '/change-order/' +
          this.selectedInterestPlace.id +
          '/' +
          this.selectedInterestPlace.toMove
      ).then(res => {
        this.showSuccess(res.data.success)
        this.$refs.interestPlaces.getDataFromApi()
      })
    },
    changeStatusInterestPlace () {
      this.putMobile(
        INTEREST_PLACES_URL + '/change-status/' + this.selectedInterestPlace.id,
        this.selectedInterestPlace
      ).then(res => {
        this.showSuccess(res.data.success)
        this.$refs.interestPlaces.getDataFromApi()
      })
    },
    deleteInterestPlace () {
      this.isConfirmDeleteInterestPlace = false
      this.deleteMobile(
        INTEREST_PLACES_URL,
        this.selectedInterestPlace.id
      ).then(res => {
        this.showSuccess(res.data.success)
        this.$refs.interestPlaces.getDataFromApi()
      })
    },
    async getFair () {
      const response = await this.get(FAIRS_URL + '/' + this.$route.params.fairId)
      this.fair = response.data
    }
  },
  async created () {
    try {
      this.showLoader()
      await this.getFair()
      this.hideLoader()
    } catch {
      this.hideLoader()
      this.showError()
    }
  },
  mixins: [crudServiceMixin, crudServiceMobileMixin, notificationMixin, loaderMixin]
}
</script>
