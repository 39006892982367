<template>
  <div class="row">
    <v-dialog v-model="isActive" max-width="1000px" persistent scrollable>
      <v-card>
        <v-card-title>
          <span class="headline">
            {{ model.id ? "Edición" : "Creación" }} de Sitio de Interes
          </span>
        </v-card-title>
        <v-card-text>
          <validation-observer ref="form">
            <div class="row">
              <div class="col-12 py-1">
                <validation-provider
                  v-slot="{ errors }"
                  name="Nombre"
                  vid="name"
                  rules="required|max:255"
                >
                  <v-text-field
                    v-model="model.name"
                    type="text"
                    outlined
                    dense
                    :error-messages="errors"
                    label="Nombre"
                    required
                  />
                </validation-provider>
              </div>
              <div class="col-12 py-1">
                <validation-provider
                  v-slot="{ errors }"
                  name="Url Sitio Web"
                  vid="urlWebsite"
                  rules="max:500"
                >
                  <v-text-field
                    v-model="model.urlWebsite"
                    type="text"
                    outlined
                    dense
                    :error-messages="errors"
                    label="Url Sitio Web"
                  />
                </validation-provider>
              </div>
              <div class="col-12 py-1">
                <validation-provider
                  v-slot="{ errors }"
                  name="Url Google Maps"
                  vid="urlGoogleMap"
                  rules="max:500"
                >
                  <v-text-field
                    v-model="model.urlGoogleMap"
                    type="text"
                    outlined
                    dense
                    :error-messages="errors"
                    label="Url Sitio en Google Maps"
                  />
                </validation-provider>
              </div>
              <div class="col-12 py-1">
                <validation-provider
                  v-slot="{ errors }"
                  name="Url Waze"
                  vid="urlWazeMap"
                  rules="max:500"
                >
                  <v-text-field
                    v-model="model.urlWazeMap"
                    type="text"
                    outlined
                    dense
                    :error-messages="errors"
                    label="Url Sitio en Waze"
                  />
                </validation-provider>
              </div>
            </div>
          </validation-observer>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="saveInterestPlace">
            Aceptar
          </v-btn>
          <v-btn color="red" text @click="close">
            Cancelar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import crudServiceMobileMixin from '@/mixins/crudServiceMobileMixin'
import loaderMixin from '@/mixins/loaderMixin'
import notificationMixin from '@/mixins/notificationMixin'
import { INTEREST_PLACES_URL } from '@/constants/ServicesMobileConstants'

export default {
  name: 'interest-place-modal',
  props: {
    order: {
      type: Number,
      default: null
    },
    fairId: {
      type: Number,
      default: null
    }
  },
  data () {
    return {
      isActive: false,
      model: {
        id: null,
        name: null,
        urlWebsite: null,
        urlGoogleMap: null,
        urlWazeMap: null,
        order: this.order,
        fairId: null
      }
    }
  },
  methods: {
    close () {
      this.isActive = false
      this.clean()
    },
    clean () {
      this.$refs.form.reset()
      this.model = {
        id: null,
        name: null,
        urlWebsite: null,
        urlGoogleMap: null,
        urlWazeMap: null,
        order: null
      }
    },
    open (interestPlace) {
      this.isActive = true
      if (interestPlace) {
        this.model = interestPlace
      }
    },
    saveInterestPlace () {
      this.model.fairId = this.fairId
      this.$refs.form.validate().then((result) => {
        if (result) {
          if (this.model.id) {
            this.putMobile(INTEREST_PLACES_URL + '/' + this.model.id, this.model)
              .then((res) => {
                this.close()
                this.showSuccess(res.data.success)
                this.$emit('onClose')
              })
          } else {
            this.postMobile(INTEREST_PLACES_URL, this.model)
              .then((res) => {
                this.close()
                this.showSuccess(res.data.success)
                this.$emit('onClose')
              })
          }
        }
      })
    }
  },
  mixins: [
    crudServiceMobileMixin,
    notificationMixin,
    loaderMixin
  ]
}
</script>
