const headers = [
  {
    text: 'Posición',
    value: 'order',
    sortable: false
  },
  {
    text: 'Nombre',
    value: 'name',
    sortable: false
  },
  {
    text: 'Ubicación Google',
    value: 'urlGoogleMap',
    sortable: false
  },
  {
    text: 'Ubicación Waze',
    value: 'urlWazeMap',
    sortable: false
  },
  {
    text: 'Sitio Web',
    value: 'urlWebsite',
    sortable: false
  },
  {
    text: '',
    value: 'arrows',
    sortable: false
  },
  {
    text: '',
    value: 'isActive',
    sortable: false
  },
  {
    text: '',
    value: 'actions',
    sortable: false
  }
]
export default headers
